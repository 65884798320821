import { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import _ from "../../@lodash";
import { styled } from "@mui/material/styles";
import format from "date-fns/format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { backend_url } from "../../components/Constants";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DraftWysiwygEditor from "../../forms/editor/TextEditor";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const schema = yup.object().shape({
  chief_reason: yup.string().notRequired(""),
});

export default function HOPSSummary(props) {
  const {
    token,
    id,
    setChief_confirm,
    chief_confirm,
    trip,
    depRet,
    delegate_data,
  } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const defaultValues = {
    chief_reason: "",
  };

  const delLength = delegate_data.length;
  const days =
    (new Date(depRet.date_of_return).getTime() -
      new Date(depRet.date_of_dep).getTime()) /
    (1000 * 3600 * 24);
  const days_rounded =
    days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;

  const tot_cost = [
    parseInt(trip.accomo_cost) +
      parseInt(trip.ticket_cost) +
      parseInt(trip.participant_cost),
  ];

  const sums = tot_cost.reduce((a, x, i) => [...a, x + (i && a[i - 1])], []);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  const navigate = useNavigate();

  function submit({ chief_reason }) {
    const checkingInfo = {
      trip_id: id,
      chief_confirm,
      chief_reason,
      reviewer:
        trip.user.designation === "CS"
          ? false
          : chief_confirm === "return_application"
          ? false
          : chief_confirm === "accepted"
          ? true
          : false,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, checkingInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  }

  return (
    <div className="py-4 w-full max-w-3xl">
      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-lg font-bold"
      >
        TRAVEL CLEARANCE REQUEST SUMMARY FOR TRIP {id}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Application Ref: No
              </StyledTableCell>
              <StyledTableCell align="left">{id}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Date of application:
              </StyledTableCell>
              <StyledTableCell align="left">
                {format(new Date(trip.created_at), "dd-MMM-yyyy")}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Date Received:
              </StyledTableCell>
              <StyledTableCell align="left">
                {format(new Date(trip.updated_at), "dd-MMM-yyyy")}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Applicant:
              </StyledTableCell>
              <StyledTableCell align="left">
                {`${
                  trip.user.salutation !== undefined && trip.user.salutation
                }. ${trip.user.first_name}  ${trip.user.last_name}`}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Designation:
              </StyledTableCell>
              <StyledTableCell align="left">
                {trip.user.designation}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                MDA:
              </StyledTableCell>
              <StyledTableCell align="left">
                {trip.user.ministry}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Days Requested:
              </StyledTableCell>
              <StyledTableCell align="left">{`${days_rounded} days`}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Dates:
              </StyledTableCell>
              <StyledTableCell align="left">{`${format(
                new Date(depRet.date_of_dep),
                "dd-MMM-yyyy"
              )} -  ${format(
                new Date(depRet.date_of_return),
                "dd-MMM-yyyy"
              )}`}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                No. of Days Remaining on Applicants Docket:
              </StyledTableCell>
              <StyledTableCell align="left">
                {15 - trip.days_this_quarter}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Purpose for travel:
              </StyledTableCell>
              <StyledTableCell align="left">
                {/* {trip.reason_for_tr} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: trip.reason_for_tr,
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Destination:
              </StyledTableCell>
              <StyledTableCell align="left">{trip.country}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Delegation Nos:
              </StyledTableCell>
              <StyledTableCell align="left">{delLength}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Total Cost(KES):
              </StyledTableCell>
              <StyledTableCell align="left">
                {sums[sums.length - 1].toLocaleString()}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Sponsor:
              </StyledTableCell>
              <StyledTableCell align="left">{trip.sponsor}</StyledTableCell>
            </StyledTableRow>
            {console.log(trip.chairperson_reason === "")}
            {trip.chairperson_reason !== null &&
              trip.chairperson_reason !== "" &&
              (trip.chairperson_confirm === "accepted" ||
                trip.chairperson_confirm === "accepted") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    Chairperson Comments:
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {trip.chairperson_reason}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            {trip.ps_reasons !== null &&
              trip.ps_reasons !== "" &&
              (trip.ps_confirm === "accepted" ||
                trip.ps_confirm === "rejected") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    PS Comments:
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {trip.ps_reasons}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            {trip.cs_reasons !== null &&
              trip.cs_reasons !== "" &&
              (trip.cs_confirm === "accepted" ||
                trip.cs_confirm === "rejected") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    CS Comments:
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {trip.cs_reasons}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            {trip.hops_checking_reasons !== null &&
              trip.hops_checking_reasons !== "" &&
              (trip.hops_checking_confirm === "accepted" ||
                trip.hops_checking_confirm === "rejected") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    <Typography variant="p" className="text-12 font-medium">
                      Reviewer Comments:
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: trip.hops_checking_reasons,
                      }}
                    />
                    {/* <Typography variant="p" className="text-12 font-medium">
                      {trip.hops_checking_reasons}
                    </Typography> */}
                  </StyledTableCell>
                </StyledTableRow>
              )}

            {trip.hops_checking_recommendation &&
              (trip.hops_checking_confirm === "accepted" ||
                trip.hops_checking_confirm === "rejected") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    <Typography variant="p" className="text-12 font-medium">
                      Reviewer Recommendations:
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography variant="p" className="text-12 font-medium">
                      {trip.hops_checking_recommendation}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {trip.chief_confirm === "not_confirmed" && (
        <form
          name="checkingForm"
          noValidate
          className="flex flex-col justify-center w-full mt-4 sm:pt-4 sm:mt-8 mb-16"
          onSubmit={handleSubmit(submit)}
        >
          {openMessage === true && (
            <TravelSnackbar
              openMessage={openMessage}
              severity={severity}
              message={message}
              uploads={true}
            />
          )}

          {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "Are you certain you have chosen to confirm or reject this application?"
            }
          </DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                handleSubmit(submit);
                setChief_confirm("rejected");
                setOpenMessage(false);
              }}
              startIcon={<DoDisturbIcon />}
              // type="submit"
            >
              {loading === true ? <Loading /> : "REJECT CONFIRMATION"}
            </Button>

            <Button
              variant="contained"
              color="success"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                handleSubmit(submit);
                setChief_confirm("accepted");
                setOpenMessage(false);
              }}
              startIcon={<CheckCircleOutlineIcon />}
              // type="submit"
            >
              {loading === true ? <Loading /> : "MARK AS CONFIRMED"}
            </Button>
          </DialogActions>
        </Dialog> */}

          {/* <Controller
            name="chief_reason"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Comments:"
                id="chief_reason"
                variant="outlined"
                sx={{ p: 1 }}
                error={!!errors.chief_reason}
                helperText={errors?.chief_reason?.message}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          /> */}

          <DraftWysiwygEditor
            control={control}
            name="chief_reason"
            label="Comments:"
            errors={errors}
          />

          <div className="flex items-center mt-16 sm:mx-8 space-x-6">
            {trip.user.designation !== "CS" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setChief_confirm("return_application");
                  setOpenMessage(false);
                }}
                startIcon={<ArrowBackIcon />}
                type="submit"
              >
                {loading === true ? <Loading /> : "Return for amendment"}
              </Button>
            )}

            <Button
              // onClick={handleClickOpen}
              variant="contained"
              color="error"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                setChief_confirm("rejected");
                setOpenMessage(false);
              }}
              startIcon={<DoDisturbIcon />}
              type="submit"
            >
              {loading === true ? (
                <Loading />
              ) : trip.user.designation === "CS" ? (
                "Not Concurred"
              ) : (
                "REJECT"
              )}
            </Button>

            <Button
              // onClick={handleClickOpen}
              variant="contained"
              color="success"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                setChief_confirm("accepted");
                setOpenMessage(false);
              }}
              startIcon={<CheckCircleOutlineIcon />}
              type="submit"
            >
              {loading === true ? (
                <Loading />
              ) : trip.user.designation === "CS" ? (
                "Concurred"
              ) : (
                "APPROVE"
              )}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
